import React from "react";

function Head() {
    return (


        <div className="site">
            <header className="header" >
                <h1 className="Title" >Morris Construction LLC</h1>
                <img className='Mlogo' alt='Mlogo' src='/Logo.png'></img>
            </header>
        </div>
    );
};

export default Head;